import React from "react";

import { Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

import { OrderLine } from "../../pages/orders/types";

type OrderDetailsProps = {
  orderLines: OrderLine[],
  currency: string
};
export const OrderDetails: React.FC<OrderDetailsProps> = ({ orderLines, currency }) => {
  const calculatedTotal = orderLines.reduce((total, line) => {
    return total + (line.amount);
  }, 0);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}><Typography variant="subtitle1">Offer name</Typography></Grid>
        <Grid item xs={3}><Typography variant="subtitle1">Discount</Typography></Grid>
        <Grid item xs={2}><Typography variant="subtitle1">Quantity</Typography></Grid>
        <Grid item xs={2}><Typography variant="subtitle1">Price per unit</Typography></Grid>
        <Grid item xs={2}><Typography variant="subtitle1">Subtotal</Typography></Grid>
      </Grid>
      <Divider/>
      {orderLines.map((line, index) => (
        <React.Fragment key={index}>
          <Grid container spacing={2} key={index}>
            <Grid item xs={3}><Typography>{line.offer.name}</Typography></Grid>
            <Grid item xs={3}><Typography>{line.discount?.name || "-"}</Typography></Grid>
            <Grid item xs={2}><Typography>{line.quantity}</Typography></Grid>
            <Grid item xs={2}>
              <Typography sx={{ mb: 2 }} >
                {line.offer.price ? (
                  line.discount ? (
                    <>
                      <del>{line.offer.price}{" "}{currency}</del> {" "}
                      {line.amount / line.quantity}{" "}{currency}
                    </>
                  ) : (
                    <>{line.offer.price}{" "}{currency}</>
                  )
                ) : (
                  <>{line.amount} {currency}</>
                )}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ mb: 2 }} >
                {line.offer.price ? (
                  line.discount ? (
                    <>
                      <del>{line.quantity * line.offer.price}{" "}{currency}</del> {" "}
                      {line.amount}{" "}{currency}
                    </>
                  ) : (
                    <>{line.quantity * line.offer.price}{" "}{currency}</>
                  )
                ) : (
                  <>{line.quantity * line.amount} {currency}</>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Divider/>
        </React.Fragment>
      ))}
      <Grid container spacing={2}>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1" align="right">Total:</Typography>
        </Grid>
        <Grid item xs={2}>
          {calculatedTotal}{" "}{currency}
        </Grid>
      </Grid>
    </>
  );
};