import { StoveCollection } from "../components/stove/stovecollection/types";
import { FullStove } from "../pages/stoves/StoveShow";

export enum Country {
  ZM = "Zambia",
  MW = "Malawi",
  MZ = "Mozambique"
}

export const CountryCurrency: Record<string, string> = {
  "ZM": "ZMW",
  "MW": "MWK",
  "MZ": "MZN"
};

export type CountryHavingObject = {
  country: string
};

export enum StoveStatus {
  ACTIVE = "Active",
  INACTIVE = "Repossessed"
}

export enum DeviceStatus {
  ACTIVE = "Active",
  INACTIVE = "Repossessed"
}

export enum CustomerStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive"
}

export enum CustomerType {
  CLIENT = "Client",
  LEAD = "Lead"
}

export enum CustomerGroupType {
  UTILITY = "Utility",
  NON_UTILITY = "Non Utility",
  TEST = "Test",
  RESELLER = "Reseller",
  SHOP = "Shop",
  STAFF = "Staff"
}

export enum ConnectionType {
  CONNECTED = "Connected",
  NOT_CONNECTED = "Not Connected"
}

export enum StoveModel {
  MIMIMOTO = "Mimimoto",
  SUPAMOTO = "Supamoto",
  PCB = "PCB"
}

export type CustomerWallet = {
  currency: string,
  amount: number
};

export type Device = {
  id: number,
  status: keyof typeof DeviceStatus,
  nativeId: number,
  deviceId: number,
  model: keyof typeof StoveModel,
  connectionType?: keyof typeof ConnectionType,
  stoves: FullStove[],
  newTimeUnlockedUntil?: string,
  timeUnlockedUntil?: string,
  nftStoveCollection?: StoveCollection,
  nftEnabled?: boolean,
  nftId?: number,
  did?: string,
  timeCreated: string,
  ixoAddress?: string
};

export type Stove = CountryHavingObject & {
  id: number,
  instanceId: number,
  nativeId: number,
  deviceId: number,
  model: keyof typeof StoveModel,
  status: keyof typeof StoveStatus,
  connectionType?: keyof typeof ConnectionType,
  timeCreated: string,
  timeInactivated?: string,
  inactivationReason?: keyof typeof AllStoveInactivationReason,
  nftEnabled?: boolean,
  nftCollectionName?: string,
  nftId?: number,
  did?: string,
  ixoAddress?: string
};

export type Location = {
  id: number,
  parentId: number,
  name: string,
  level: number
};

export type PhoneNumber = {
  number: string,
  isPrimary: boolean
};

export type Customer = {
  id: number,
  externalId: number,
  freshdeskId?: number,
  firstName: string,
  lastName: string,
  contractReference: string,
  type: keyof typeof CustomerType,
  customerStatus: keyof typeof CustomerStatus,
  groupType: keyof typeof CustomerGroupType,
  isFullyPaid: boolean,
  gender: string,
  address?: string,
  locationsHierarchy: Location[],
  latitude: number,
  longitude: number,
  phoneNumbers: PhoneNumber[],
  timeRegistered: string,
  timeInactivated?: string,
  timeUpdated: string,
  wallet: CustomerWallet
} & CountryHavingObject;

export type FullCustomer = Customer & {
  stoves: Stove[]
};

export enum RepossessionReason {
  CHANGE_OF_LOCATION = "Change of Location",
  FAULTY_DEVICE_CONTINUOUS_BREAKDOWN_AND_REPAIR = "Faulty Device (Continuous Breakdown and Repair)",
  FAULTY_DEVICE_DELAYED_REPAIR = "Faulty Device (Delayed Repair)",
  NON_COMPLIANCE_CHANGE_OF_OWNERSHIP = "Non-Compliance (Change of Ownership)",
  NON_COMPLIANCE_CONTRACT_PAYMENT = "Non-Compliance (Contract Payment)",
  SELF_REPOSSESSION_COULD_NOT_MANAGE_MAINTENANCE = "Self Repossession (Could not Manage Maintenance)",
  SELF_REPOSSESSION_HAS_ANOTHER_DEVICE = "Self Repossession (Has Another Device)",
  SELF_REPOSSESSION = "Self-Repossession",
  SELF_REPOSSESSION_FINANCIAL_CONSTRAINTS = "Self-Repossession (Financial Constraints)",
  SELF_REPOSSESSION_LOW_PELLET_CONSUMPTION = "Self-Repossession (Low Pellet Consumption)",
  SELF_REPOSSESSION_MINIMAL_DEVICE_USAGE = "Self-Repossession (Minimal Device Usage)",
  SELF_REPOSSESSION_NO_LONGER_INTERESTED = "Self-Repossession (No longer Interested)",
  THEFT_LOSS_OF_DEVICE_DEFAULT = "Theft/Loss of Device(Default)",
  UNCLASSIFIED = "Unclassified",
  UNIQUE_SCENARIO = "Unique Scenario"
}

export enum SwapDeviceReason {
  WRONG_ASSIGNMENT_DURING_ON_ONBOARDING = "Wrong assignment during on onboarding",
  DAMAGED_STOVE = "Damaged stove",
  WRONGLY_MAPPED = "Wrongly mapped"
}

export enum AutomaticStoveInactivationReason {
  AUTOMATICALLY_DURING_REMAPPING = "Automatically during remapping"
}

export const AllStoveInactivationReason = {
  ...RepossessionReason,
  ...SwapDeviceReason,
  ...AutomaticStoveInactivationReason
};