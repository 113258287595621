import React from "react";

import StoveIcon from "@mui/icons-material/OutdoorGrill";
import { NullableBooleanInput } from "react-admin";

import { UserRoles } from "../../core/providers/auth/roles";
import { useNumberList } from "../../pages/companies/use-number-list";
import { ConnectionType, StoveModel, StoveStatus } from "../../utils/commons";
import { useCheckAccess } from "../../utils/use-check-access";
import { useStoveCollectionFilter } from "../stove/stovecollection/use-stove-collection-filter";
import { baseMultilineTextInputFilter } from "./base-multiline-text-input-filter";
import { enumSelectFilter } from "./enum-select-filter";
import { buildLabel } from "./filter-utils";

export const useStoveUidFilter = (alwaysOn: boolean) => {
  const { textToArray, arrayToText } = useNumberList();

  return baseMultilineTextInputFilter({
    source: "nativeStoveIds",
    label: buildLabel({ label: "Stove UID", Icon: StoveIcon }),
    parse: textToArray,
    format: arrayToText,
    alwaysOn: alwaysOn
  });
};

export const useDeviceIdFilter = (alwaysOn: boolean) => {

  return baseMultilineTextInputFilter({
    source: "deviceIds",
    label: buildLabel({ label: "Device ID", Icon: StoveIcon }),
    alwaysOn: alwaysOn
  });
};

export const stoveStatusFilter = () => enumSelectFilter({
  source: "stoveStatuses",
  enumObj: StoveStatus,
  label: buildLabel({ label: "Stove status", Icon: StoveIcon })
});

export const stoveConnectionTypeFilter = () => enumSelectFilter({
  source: "connectionTypes",
  enumObj: ConnectionType,
  label: buildLabel({ label: "Stove connection type", Icon: StoveIcon })
});

export const stoveModelFilter = () => enumSelectFilter({
  source: "models",
  enumObj: StoveModel,
  label: buildLabel({ label: "Stove model", Icon: StoveIcon })
});

export const useStoveDidsFilter = () => {
  return baseMultilineTextInputFilter({
    source: "dids",
    label: buildLabel({ label: "Stove Did", Icon: StoveIcon })
  });
};

export const useStoveIxoAddressesFilter = () => {
  return baseMultilineTextInputFilter({
    source: "ixoAddresses",
    label: buildLabel({ label: "Ixo Address", Icon: StoveIcon })
  });
};

export const useStoveNftIdsFilter = () => {
  const { textToArray, arrayToText } = useNumberList();

  return baseMultilineTextInputFilter({
    source: "nftIds",
    label: buildLabel({ label: "Nft ID", Icon: StoveIcon }),
    parse: textToArray,
    format: arrayToText
  });
};

export const useStoveFilters = ({
  stoveUidsAlwaysOn = true,
  deviceIdsAlwaysOn = true
}) => {
  const { hasAccess: isAdmin } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);
  const collectionFilters = [
    useStoveCollectionFilter(),
    useStoveDidsFilter(),
    useStoveIxoAddressesFilter(),
    useStoveNftIdsFilter()
  ];
  return [
    useStoveUidFilter(stoveUidsAlwaysOn),
    useDeviceIdFilter(deviceIdsAlwaysOn),
    stoveStatusFilter(),
    <NullableBooleanInput key="nftEnabled"
      label={buildLabel({ label: "Is NFT", Icon: StoveIcon })}
      variant="filled"
      source="nftEnabled"/>,
    ...(isAdmin ? collectionFilters : []),
    stoveModelFilter()
  ];
};
