import React from "react";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { SECONDARY_COLOR } from "../../../core/theme/theme";
import { ErrorData, UploadFeedback } from "./UploadFeedback";

type ImportDataConfirmProps = {
  dialogTitle: string|React.ReactNode,
  isOpened: boolean,
  data: ErrorData,
  onClose: () => void,
  onConfirm: () => void
};
export const ImportDataConfirmPopup: React.FC<ImportDataConfirmProps> = ({
  dialogTitle,
  data,
  isOpened,
  onClose,
  onConfirm
}) => {
  return <Dialog open={isOpened}>
    <DialogTitle>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {dialogTitle}
      </Box>
    </DialogTitle>
    <DialogContent>
      <Box sx={{ fontSize: 14, color: SECONDARY_COLOR }}>
        {data.message}
      </Box>
      <UploadFeedback data={data}/><br/>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>
        Cancel
      </Button>
      {
        <Button onClick={onConfirm}>
          {"Confirm"}
        </Button>
      }
    </DialogActions>
  </Dialog>;
};