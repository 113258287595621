import React, { useEffect, useState } from "react";

import CallSplitIcon from "@mui/icons-material/CallSplit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider, TextField,
  Tooltip,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { head, isNil } from "lodash-es";
import { useNotify } from "ra-core";
import {
  useGetOne,
  useListContext,
  useRefresh,
  useUnselectAll,
  useUpdate
} from "react-admin";
import * as yup from "yup";

import { ItemTitle } from "../../../components/customer/CustomerBaseGrid";
import {
  SearchCustomerAutocomplete
} from "../../../components/customer/SearchCustomerAutocomplete";
import { FormTextField } from "../../../components/form/input/text-field/FormTextField";
import { FormProps, useForm } from "../../../components/form/use-form";
import { yupNumber } from "../../../components/form/validation";
import { UserRoles } from "../../../core/providers/auth/roles";
import { Resources } from "../../../resources";
import { Customer, FullCustomer } from "../../../utils/commons";
import { customerRenderer } from "../../../utils/field-renderers";
import { useCheckAccess } from "../../../utils/use-check-access";
import { usePageSafeSelector } from "../../../utils/use-page-safe-selector";
import { customerBalanceRenderer } from "../../customers/renderers";
import { Payment, PaymentTransaction } from "../types";
import { InactiveCustomerAlert } from "./InactiveCustomerAlert";
import { hasEnoughFunds } from "./InsufficientFundsAlert";

type TargetCustomerFormProps = {
  customer: FullCustomer,
  form: FormProps<SplitPaymentForm>,
  index: number
};

type CustomerAllocation = {
  customer: FullCustomer,
  customerId: number,
  amount?: number
};

type SplitPaymentForm = {
  allocations: CustomerAllocation[]
};

const initialState = {
  allocations: []
};

const allocationValidationSchema = yup.object({
  amount: yupNumber().required().label("Amount").positive()
});

const validationSchema = yup.object({
  allocations: yup.array().of(allocationValidationSchema).min(1).label("Target Customers")
});

const getSourceCustomerAllocation = (customer: Customer, form?: FormProps<SplitPaymentForm>) => {
  return form?.value.allocations
    .find((allocation: CustomerAllocation) => allocation.customerId === customer?.id)?.amount || 0;
};

const calcTotalAmount = (orderLines: CustomerAllocation[]) => {
  return orderLines.reduce((total: number, orderItem: CustomerAllocation) => {
    return total + +(orderItem.amount || 0);
  }, 0);
};

export const TargetCustomerForm: React.FC<TargetCustomerFormProps> = ({
  customer,
  form,
  index
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <ItemTitle title={"Name"}/>
        {customerRenderer(customer)}
      </Grid>
      <Grid item xs={3}>
        <ItemTitle title={"Contract Reference"}/>
        <Typography>{customer?.contractReference}</Typography>
      </Grid>
      <Grid item xs={4}>
        <FormTextField
          name={`allocations[${index}].amount`}
          label="Amount"
          type="number"
          required
          form={form}
        />
      </Grid>
      <Grid item xs={2}
        sx={{ display: "flex", justifyContent: "center", alignItems: "start" }}>
        <Button
          sx={{ mt: 1 }}
          onClick={() => form.removeArrayElement("allocations", index)}
          startIcon={<DeleteIcon/>}>
            Remove
        </Button>
      </Grid>
      <Grid item xs={12}><Divider/></Grid>
    </Grid>
  );
};

export const SplitPaymentButton = () => {
  const refresh = useRefresh();
  const unselect = useUnselectAll(Resources.Payments);
  const notify = useNotify();
  const { selectedIds } = useListContext();
  const { hasAccess } = useCheckAccess([
    UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_DELIVERY_MANAGER,
    UserRoles.ROLE_SHOP_KEEPER
  ]);
  const form = useForm<SplitPaymentForm>(initialState, validationSchema);
  const { data } = usePageSafeSelector<Payment>();
  const [payment, setPayment] = useState<Payment | undefined>();
  const [isSplitPaymentDialogOpen, setIsSplitPaymentDialogOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [isSplitPaymentRequestInProgress, setIsSplitPaymentRequestInProgress] = useState(false);

  const handleCancel = () => {
    setIsConfirmationDialogOpen(false);
    setIsSplitPaymentDialogOpen(false);
    form.reset();
  };

  const [ update, { isLoading: isProcessing } ] = useUpdate();

  const handleConfirm = () => {
    if (isSplitPaymentRequestInProgress) {
      return;
    }
    setIsSplitPaymentRequestInProgress(true);
    setIsConfirmationDialogOpen(false);

    const allocationsToSubmit =
        form.value.allocations.map((allocation: CustomerAllocation) => ({
          customerId: allocation.customerId,
          amount: allocation.amount
        }));

    return update(Resources.Payments,
      {
        id: payment?.id,
        data: {
          allocations: allocationsToSubmit
        }
      }, {
        onSuccess: () => {
          refresh();
          notify("Payment has been successfully splitted");
          unselect();
          handleCancel();
          setIsSplitPaymentRequestInProgress(false);
        },
        onError: (error: any) => {
          const errorCode = error.body?.errorCode;
          if (errorCode && errorCode === "wallet.invalid_balance") {
            refresh();
            notify("The payment cannot be splitted due to insufficient funds in the customer's wallet", { type: "warning" });
          } else {
            notify("Error: failed to split payment", { type: "error" });
          }
          setIsSplitPaymentRequestInProgress(false);
        }
      });
  };

  const totalAmountMatchesPaymentAmount = () => {
    return payment?.amount === calcTotalAmount(form.value.allocations);
  };

  const isFormValid = () => {
    const assignToInactiveCustomer = form.value.allocations.some((allocation: CustomerAllocation) => {
      return allocation.customer.customerStatus === "INACTIVE";
    });
    return !assignToInactiveCustomer && !isProcessing && form.isValid && totalAmountMatchesPaymentAmount() &&
        (isNil(sourceCustomer) ||
            hasEnoughFunds(sourceCustomer, ((payment?.amount ?? 0) -
                getSourceCustomerAllocation(sourceCustomer, form))));
  };

  const sourceCustomerId =
      payment?.transactions
        .filter((transaction: PaymentTransaction) => transaction.activeAssignment)
        .map((transaction: PaymentTransaction) => transaction.customerId)?.[0] as number;
  const activeAssignmentCount =
      payment?.transactions
        .filter((transaction: PaymentTransaction) => transaction.activeAssignment)?.length || 0;
  const targetCustomerIds =
      form.value.allocations.map((allocation: CustomerAllocation) => allocation.customerId);

  const options = { enabled: !isNil(payment) && !isNil(sourceCustomerId), cacheTime: 0, staleTime: 0 };
  const {
    data: sourceCustomer
  } = useGetOne<Customer>(Resources.Customers, { id: sourceCustomerId }, options);

  useEffect(() => {
    setPayment(head(data));
  }, [data]);

  const customerHasEnoughFunds = isNil(sourceCustomer) ||
      hasEnoughFunds(sourceCustomer, ((payment?.amount ?? 0) - getSourceCustomerAllocation(sourceCustomer, form)));
  return (
    <>
      <Tooltip title="Split an orphaned payment or reconcile it between multiple customers">
        <span>
          <Button
            color="primary"
            disabled={!hasAccess || selectedIds.length > 1 || activeAssignmentCount > 1}
            onClick={() => setIsSplitPaymentDialogOpen(true)}
            startIcon={<CallSplitIcon/>}>
              Split Payment
          </Button>
        </span>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isSplitPaymentDialogOpen}
        onClose={handleCancel}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                {isNil(sourceCustomerId) ? "Payment" : "Source customer and payment"} data:</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                { (!isNil(sourceCustomerId) && sourceCustomer) && (
                  <>
                    <Grid item xs={3}>
                      <ItemTitle title="Name"/>
                      {customerRenderer(sourceCustomer)}
                    </Grid>
                    <Grid item xs={3}>
                      <ItemTitle title="Contract Reference"/>
                      <Typography>{sourceCustomer?.contractReference}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <ItemTitle title={"Wallet Balance"}/>
                      <Typography>{customerBalanceRenderer(sourceCustomer)}</Typography>
                    </Grid>
                  </>
                )
                }
                <Grid item xs={3}>
                  <ItemTitle title="Payment Amount"/>
                  <Typography>{payment?.amount} {payment?.currency}</Typography>
                </Grid>
              </Grid>
            </Grid>
            {
              !isNil(payment) && !customerHasEnoughFunds
                && (
                  <Grid item xs={12}>
                    <Alert severity="warning">
                        Insufficient funds in the customer&apos;s wallet.<br/>
                      { payment?.amount && sourceCustomer?.wallet?.amount
                        ? (
                          <>
                            {/* eslint-disable-next-line max-len */}
                                  The customer should be added to the target customers list with a minimal amount: <strong>{payment.amount - sourceCustomer?.wallet?.amount} {payment?.currency}</strong><br/>
                          </>
                        ) : null}
                    </Alert>
                  </Grid>
                )
            }
            {
              form.value.allocations.map((allocation: CustomerAllocation) => (
                allocation.customer.customerStatus === "INACTIVE" && (
                  <InactiveCustomerAlert customer={allocation.customer}/>
                )
              ))
            }
            <Grid item xs={12}>
              <Divider sx={{ my: 1 }}/>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Target customers:</Typography>
            </Grid>
            <Grid item xs={12}>
              <SearchCustomerAutocomplete
                customerIdsToExclude={targetCustomerIds}
                filters={{
                  countries: [payment?.country]
                }}
                onCustomerSelected={(customer) => {
                  form.setValueByLabel(`allocations[${form.value.allocations.length}]`,
                    { customer: customer, customerId: customer.id });
                }} />
            </Grid>
            {
              form.value.allocations.map((allocation: CustomerAllocation, index: number) => (
                <Grid item xs={12} key={index}>
                  <TargetCustomerForm customer={allocation.customer} form={form} index={index}/>
                </Grid>
              ))
            }
            { form.value.allocations?.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Total amount"
                        variant="outlined"
                        disabled
                        fullWidth
                        value={calcTotalAmount(form.value.allocations)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {!totalAmountMatchesPaymentAmount() && (
                  <Grid item xs={12}>
                    <Alert severity="warning">
                          Total amount must be equal to payment amount: <strong>{payment?.amount}</strong>
                    </Alert>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            disabled={!isFormValid() || isProcessing}
            onClick={() => setIsConfirmationDialogOpen(true)}>
            {isProcessing ?
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <CircularProgress color="secondary" size={20}/> Processing
              </Box>
              : "Confirm"
            }
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isConfirmationDialogOpen}
        onClose={() => setIsConfirmationDialogOpen(false)}
      >
        <DialogTitle>Payment to be splitted:</DialogTitle>
        <DialogContent>
          <DialogContent sx={{ p: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>Transaction ID:</Grid>
              <Grid item xs={6}><strong>{payment?.telcoTransactionId}</strong></Grid>
              <Grid item xs={6}>Amount:</Grid>
              <Grid item xs={6}><strong>{payment?.amount} {payment?.currency}</strong></Grid>
              {
                !isNil(sourceCustomer) && (
                  <>
                    <Grid item xs={6}>From:</Grid>
                    <Grid item xs={6}>{customerRenderer(sourceCustomer)}</Grid>
                  </>
                )
              }
              <Grid item xs={6}>To:</Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  {form.value.allocations.map((allocation: CustomerAllocation) => (
                    <Grid item xs={12} key={allocation.customerId}>
                      {customerRenderer(allocation.customer)}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="body1">Kindly confirm or cancel the action.</Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmationDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            autoFocus
            disabled={isSplitPaymentRequestInProgress}
            onClick={handleConfirm}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};