import React, { SyntheticEvent, useEffect, useState } from "react";

import { AutocompleteChangeReason } from "@mui/base/useAutocomplete/useAutocomplete";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip, Typography
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import { head, isEmpty, isNil, uniq } from "lodash-es";
import { useNotify } from "ra-core";
import { useDataProvider, useRefresh, useUnselectAll, useUpdateMany } from "react-admin";

import { CollectionPartner, CollectionPartnerType } from "../../../components/collections/types";
import { CollectionPartnerStockBalance, StockBalance } from "../../../components/stock/types";
import { UserRoles } from "../../../core/providers/auth/roles";
import { Resources } from "../../../resources";
import { Customer } from "../../../utils/commons";
import { useCheckAccess } from "../../../utils/use-check-access";
import { usePageSafeSelector } from "../../../utils/use-page-safe-selector";
import { Order, OrderStatus } from "../types";

type Props = {
  disabled: boolean
};

type UpdateOrdersRequest = {
  status: keyof typeof OrderStatus,
  pickUpOwnerId: number
};

const collectionPartnerTypeMenuItems = Object.entries(CollectionPartnerType).map(([key, value]) => (
  <MenuItem key={key} value={key}>{value}</MenuItem>
));

const stockBalanceWillBeBelowZero = (stockBalance: CollectionPartnerStockBalance, orders: Order[]) => {
  const orderStockItemQuantities = new Map<number, number>();
  orders.forEach((order) => {
    order.orderLines
      .filter((line) => line.offer.stockItemId)
      .forEach((line) => {
        if (line.offer.stockItemId) {
          const stockItemId = line.offer.stockItemId;
          const currentQuantity = orderStockItemQuantities.get(stockItemId) || 0;
          orderStockItemQuantities.set(stockItemId, currentQuantity + line.quantity);
        }
      });
  });
  const allStockBalances: StockBalance[] = Object.values(stockBalance.stockBalances).flat();
  for (const [stockItemId, orderedQuantity] of Array.from(orderStockItemQuantities.entries())) {
    const balance = allStockBalances.find((balance) => balance.stockItemId === stockItemId);
    if (!balance || (balance.quantity < orderedQuantity)) {
      return true;
    }
  }

  return false;
};

export const MarkAsPickedUpButton: React.FC<Props> = ({
  disabled
}) => {
  const refresh = useRefresh();
  const unselect = useUnselectAll(Resources.Orders);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { hasAccess: isShopKeeper, user } = useCheckAccess(UserRoles.ROLE_SHOP_KEEPER);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { selectedIds, data: orders } = usePageSafeSelector<Order>();
  const [orderCountries, setOrderCountries] = useState<string[]>([]);

  const [isCollectionsLoading, setIsCollectionsLoading] = useState(false);
  const [isStockBalanceLoading, setIsStockBalanceLoading] = useState(false);
  const [collectionPartnerType, setCollectionPartnerType] =
      useState<keyof typeof CollectionPartnerType | "">("");
  const [userIdsFilter, setUserIdsFilter] = useState<number[]>([]);
  const [collectionPartners, setCollectionPartners] = useState<readonly CollectionPartner[]>([]);
  const [stockBalance, setStockBalance] = useState<CollectionPartnerStockBalance>();
  const [pickUpOwnerId, setPickUpOwnerId] = useState<number>();
  const [ update, { isLoading: isProcessing } ] = useUpdateMany();

  useEffect(() => {
    if (!isShopKeeper) return;

    setUserIdsFilter([user?.id as number]);
  }, [isShopKeeper, user]);

  const handleCancel = () => {
    setIsDialogOpen(false);

    if (!isShopKeeper) {
      setCollectionPartnerType("");
      setPickUpOwnerId(undefined);
    }
  };

  const handleConfirm = () => update(Resources.Orders,
    {
      ids: selectedIds,
      data: {
        status: "PICKED_UP",
        pickUpOwnerId
      } as UpdateOrdersRequest
    }, {
      onSuccess: () => {
        refresh();
        notify("Orders have been successfully updated");
        unselect();
        handleCancel();
      },
      onError: () => notify("Error: failed to update orders", { type: "error" })
    });

  useEffect(() => {
    if (isEmpty(orders)) return;

    const customerIds = uniq(orders.map((order) => order.customerId));
    dataProvider.getMany<Customer>(Resources.Customers, { ids: customerIds })
      .then((response) => {
        setOrderCountries(uniq(response.data.map((customer) => customer.country)));
        return response;
      });
  }, [dataProvider, orders]);

  useEffect(() => {
    if (isEmpty(collectionPartnerType) && !isShopKeeper) return;

    const typesFilter = isShopKeeper ? ["DEPOT", "MOBILE"] : [collectionPartnerType];
    setPickUpOwnerId(undefined);
    setIsCollectionsLoading(true);
    dataProvider.getList<CollectionPartner>(Resources.CollectionPartners, {
      pagination: {
        page: 1,
        perPage: 200
      },
      sort: {
        field: "name",
        order: "ASC"
      },
      filter: {
        userIds: userIdsFilter,
        countries: orderCountries,
        types: typesFilter,
        statuses: ["ACTIVE"]
      }
    }).then((response) => {
      setIsCollectionsLoading(false);
      setCollectionPartners(response.data);

      if (isShopKeeper) {
        setPickUpOwnerId(head(response.data)?.id);
      }
      return response;
    }).catch(() => {
      notify("Error: failed to retrieve collections", { type: "error" });
    });
  }, [collectionPartnerType, dataProvider, isShopKeeper, notify, orderCountries, userIdsFilter]);

  useEffect(() => {
    if (!pickUpOwnerId) return;

    setIsStockBalanceLoading(true);
    dataProvider.getOne<CollectionPartnerStockBalance>(Resources.StockBalances, { id: pickUpOwnerId })
      .then((response) => {
        setStockBalance(response.data);
        setIsStockBalanceLoading(false);
        return response;
      }).catch(() => {
        notify("Error: failed to retrieve stock balances", { type: "error" });
        setIsStockBalanceLoading(false);
      });
  }, [dataProvider, notify, pickUpOwnerId]);

  const onCollectionPartnerChange =
      (event: SyntheticEvent, value: CollectionPartner, reason: AutocompleteChangeReason) => {
        if (reason === "selectOption") {
          setPickUpOwnerId(value.id);
        }
      };

  return (
    <>
      <Tooltip title="Mark an order as Picked Up">
        <span>
          <Button
            color="primary"
            disabled={disabled}
            onClick={() => setIsDialogOpen(true)}
            startIcon={<DomainVerificationIcon/>}>
            Mark as Picked Up
          </Button>
        </span>
      </Tooltip>
      <Dialog
        fullWidth
        open={isDialogOpen}
        onClose={handleCancel}
      >
        <DialogTitle>Mark an order as Picked Up</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {
              !isShopKeeper ?
                <>
                  <Grid item xs={12}>
                    <Typography variant="body1">Please choose a type of collection and then select a specific one.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="collection-partner-type">Collection type *</InputLabel>
                      <Select
                        labelId="collection-partner-type"
                        label="Collection type *"
                        value={collectionPartnerType}
                        disabled={isShopKeeper}
                        onChange={(event) => {
                          setCollectionPartnerType(event.target.value as keyof typeof CollectionPartnerType);
                        }}>
                        {collectionPartnerTypeMenuItems}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={isEmpty(collectionPartnerType) || isShopKeeper}
                      loading={isCollectionsLoading}
                      options={collectionPartners}
                      onChange={onCollectionPartnerChange}
                      getOptionLabel={(option: CollectionPartner) => `${option.code}, ${option.name}`}
                      isOptionEqualToValue={
                        (option: CollectionPartner, value: CollectionPartner) => option.id === value.id
                      }
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Collection *"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isCollectionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                </> :
                <>
                  <Grid item xs={12}>
                    {
                      isNil(pickUpOwnerId) ?
                        <Box>
                          <Typography>Dear Shop Keeper</Typography>
                          <Typography sx={{ mt: 1 }}>
                            To mark an order as picked up, you must be assigned to your Shop.
                            Please contact the admins, and they will assist you.
                          </Typography>
                        </Box> :
                        <Box>
                          <Typography>Dear Shop Keeper</Typography>
                          <Typography sx={{ mt: 1 }}>
                            You are about to confirm the pick-up of an order at the&nbsp;
                            <strong>{head(collectionPartners)?.name}</strong>.<br/>
                            Please confirm or cancel.
                          </Typography>
                        </Box>
                    }
                  </Grid>
                </>
            }
            {
              orderCountries.length > 1 &&
                <Grid item xs={12}>
                  <Alert severity="warning">It appears that you have chosen orders from various countries.
                    Unfortunately, it is not feasible to mark them as picked up from one single location.
                  </Alert>
                </Grid>
            }
            { pickUpOwnerId && stockBalance && stockBalanceWillBeBelowZero(stockBalance, orders)
                 &&
                <Grid item xs={12}>
                  <Alert severity="warning">Collection Partner will have negative stock balance after order(s) completion
                  </Alert>
                </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            disabled={isNil(pickUpOwnerId) || isProcessing || isStockBalanceLoading || orderCountries.length > 1}
            onClick={handleConfirm}>
            {isProcessing ?
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <CircularProgress color="secondary" size={20}/> Processing
              </Box>
              : "Confirm"
            }
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};