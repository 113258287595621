import React from "react";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Flags from "country-flag-icons/react/3x2";

import { customerBalanceRenderer } from "../../pages/customers/renderers";
import { Customer, CustomerGroupType, CustomerType, FullCustomer } from "../../utils/commons";
import { countryNameRenderer, customerRenderer, enumRenderer } from "../../utils/field-renderers";

type Props = {
  spacing?: number,
  divider?: boolean,
  customer: FullCustomer,
  remainingBalance?: number
};

const CountryLabel: React.FC<{customer: Customer}> = ({ customer }) => {
  // @ts-ignore
  const Flag = Flags[customer.country];

  return (
    <Box sx={{
      display: "flex",
      alignItems: "center",
      gap: 1
    }}>
      <Flag width="22px"/>
      {countryNameRenderer(customer)}
    </Box>
  );
};

export const ItemTitle: React.FC<{title: string}> = ({ title }) => {
  return (
    <Box>
      <Typography variant="caption" color="secondary">{title}:</Typography>
    </Box>
  );
};

const customerStovesRenderer = (customer: FullCustomer) => {
  const stoves = customer.stoves?.filter((stove) => stove.status === "ACTIVE");
  return stoves?.length ?
    stoves.map((stove) => <Typography key={stove.nativeId}>{stove.nativeId}</Typography>) :
    (<Typography>-</Typography>);
};

const phoneNumbersRenderer = (customer: FullCustomer) => {
  return (customer.phoneNumbers || []).length ?
    customer.phoneNumbers
      .sort((phone) => phone.isPrimary ? -1 : 1)
      .map((phone) => <Typography key={phone.number}>{phone.number + (phone.isPrimary ? " (primary)" : "")}</Typography>) :
    (<Typography>-</Typography>);
};

const customerGroupRenderer = (customer: FullCustomer) => {
  const groupName = enumRenderer(customer.groupType, CustomerGroupType);
  if (customer.groupType === "NON_UTILITY") {
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {groupName}
        <Tooltip title={customer.isFullyPaid ? "Is Fully Paid" : "Is Not Fully Paid"}>
          {customer.isFullyPaid ?
            <CheckIcon fontSize="small" color="success"/> :
            <CloseIcon fontSize="small" color="warning"/>}
        </Tooltip>
      </Box>
    );
  }

  return groupName;
};

export const CustomerBaseGrid: React.FC<Props> = ({
  spacing = 1,
  divider = true,
  customer,
  remainingBalance
}) => {
  return (
    <Grid container spacing={spacing}>
      <Grid item xs={4}>
        <ItemTitle title={"Name"}/>
        {customerRenderer(customer)}
      </Grid>
      <Grid item xs={4}>
        <ItemTitle title={"Contract Reference"}/>
        <Typography>{customer.contractReference}</Typography>
      </Grid>
      <Grid item xs={4}>
        <ItemTitle title={"Country"}/>
        <CountryLabel customer={customer}/>
      </Grid>
      <Grid item xs={4}>
        <ItemTitle title={"Group"}/>
        <Box>{customerGroupRenderer(customer)}</Box>
      </Grid>
      <Grid item xs={4}>
        <ItemTitle title={"Type"}/>
        <Typography>{enumRenderer(customer.type, CustomerType)}</Typography>
      </Grid>
      <Grid item xs={4}>
        <ItemTitle title={"Wallet Balance"}/>
        <Typography>{customerBalanceRenderer(customer)}</Typography>
      </Grid>
      <Grid item xs={4}>
        <ItemTitle title={"Phone numbers"}/>
        <Box>{phoneNumbersRenderer(customer)}</Box>
      </Grid>
      <Grid item xs={4}>
        <ItemTitle title={"Stoves"}/>
        <Box>{customerStovesRenderer(customer)}</Box>
      </Grid>
      {
        (remainingBalance || remainingBalance === 0) &&
        <Grid item xs={4}>
          <ItemTitle title={"Remaining Wallet Balance"}/>
          <Typography>{`${remainingBalance} ${customer.wallet.currency}`}</Typography>
        </Grid>
      }
      {divider && <Grid item xs={12}><Divider/></Grid>}
    </Grid>
  );
};