import React from "react";

import { Box, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";

import { PRIMARY_COLOR } from "../../../core/theme/theme";

type Props = {
  Icon: React.FC<any>,
  label: string,
  hint: string,
  onClick: () => void
};

export const DeliveryTypeButton: React.FC<Props> = ({
  Icon,
  label,
  hint,
  onClick
}) => {
  return (
    <Paper sx={{
      p: 1,
      height: "150px",
      width: "250px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 1,
      cursor: "pointer",
      "&:hover": {
        borderColor: PRIMARY_COLOR
      }
    }}
    onClick={onClick}
    variant="outlined"
    >
      <Icon sx={{ fontSize: 60 }}/>
      <Box>
        <Typography variant="h5">{label}</Typography>
        <Typography variant="body2" color="text.secondary">{hint}</Typography>
      </Box>
    </Paper>
  );
};
