import moment, { Moment } from "moment";

export enum DATE_TIME_FORMAT {
  DATE_TIME_ISO = "YYYY-MM-DDTHH:mm:ss",
  DATE_ISO = "YYYY-MM-DD"
}

export const toUtcIsoString = (date: Moment): string => {
  return date.toISOString();
};

export const toIsoString = (date: Moment): string => {
  return date.format(DATE_TIME_FORMAT.DATE_TIME_ISO);
};

export const formatDate = (date: string | Date | Moment, format: DATE_TIME_FORMAT): string => {
  if (!date || !moment(date).isValid()) return "";

  return moment(date).format(format);
};