import React, { useState } from "react";

import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { isNil } from "lodash-es";

import { SECONDARY_COLOR } from "../../../core/theme/theme";
import { usePageSafeSelector } from "../../../utils/use-page-safe-selector";
import { Order } from "../types";
import { DeliveryTypeButton } from "./DeliveryTypeButton";
import { GroupDelivery } from "./group/GroupDelivery";
import { IndividualDelivery } from "./individual/IndividualDelivery";

type Props = {
  disabled: boolean
};

enum DeliveryType {
  INDIVIDUAL,
  GROUP
}

export const PelletsDeliveryAction: React.FC<Props> = ({
  disabled
}) => {
  const { data: selectedOrders } = usePageSafeSelector<Order>();

  const [deliveryType, setDeliveryType] = useState<DeliveryType>();

  const [open, setOpen] = useState(false);
  const handleDialogClose = () => {
    setOpen(false);
    setDeliveryType(undefined);
  };

  const handleConfirm = () => {
    handleDialogClose();
  };

  return (
    <>
      <Tooltip title="Create a pellets delivery task">
        <span>
          <Button
            color="error"
            disabled={disabled}
            startIcon={<DeliveryDiningIcon/>}
            onClick={() => setOpen(true)}>
              Pellets delivery
          </Button>
        </span>
      </Tooltip>

      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>Create a pellets delivery task</DialogTitle>
        {
          isNil(deliveryType) ?
            <>
              <DialogContent>
                <Typography sx={{
                  color: SECONDARY_COLOR,
                  fontSize: "18px",
                  mb: 1
                }}>Choose delivery type</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <DeliveryTypeButton
                      Icon={PersonOutlineIcon}
                      label="Individual"
                      hint="Delivery to a customer"
                      onClick={() => setDeliveryType(DeliveryType.INDIVIDUAL)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DeliveryTypeButton
                      Icon={GroupsOutlinedIcon}
                      label="Group"
                      hint="Delivery to a group leader"
                      onClick={() => setDeliveryType(DeliveryType.GROUP)}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
              </DialogActions>
            </> :
            deliveryType === DeliveryType.INDIVIDUAL ?
              <IndividualDelivery
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                orders={selectedOrders}
              /> :
              <GroupDelivery
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                orders={selectedOrders}
              />
        }
      </Dialog>
    </>
  );
};
