import React, { SyntheticEvent, useEffect, useState } from "react";

import { AutocompleteChangeReason } from "@mui/base/useAutocomplete/useAutocomplete";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Autocomplete, Box, Button, CircularProgress, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import { isNil } from "lodash-es";

import { FormTextField } from "../../../../components/form/input/text-field/FormTextField";
import { FormProps } from "../../../../components/form/use-form";
import { CountryCurrency } from "../../../../utils/commons";
import { enumRenderer } from "../../../../utils/field-renderers";
import { Discount } from "../../../discounts/types";
import { offerNameRenderer } from "../../../offers/renderers";
import { Offer, OFFER_TYPE_PRIORITY, OfferType } from "../../../offers/types";
import { calcTotalPrice, CreateOrderForm, findOffer } from "./create-order-form";
import { CreateOrderLine } from "./types";

type Props = {
  offers: Offer[],
  isOffersLoading: boolean,
  discounts: Discount[],
  isDiscountsLoading: boolean,
  form: FormProps<CreateOrderForm>
};

const buildOfferOptions = (offers: Offer[] = []): Offer[] => {
  const namesEntryCount = offers.reduce((result: Record<string, number>, value) => ({
    ...result,
    [value.name]: (result[value.name] || 0) + 1
  }), {});
  const duplicatingNames = Object.keys(namesEntryCount).filter((a) => namesEntryCount[a] > 1);
  const uniqueNameOffers = offers.map((offer) => {
    if (duplicatingNames.includes(offer.name)) {
      offer.name = `${offer.name} (Price: ${offer.price || "-"} ${CountryCurrency[offer.country]})`;
    }
    return offer;
  });

  return uniqueNameOffers.sort((a, b) => {
    return OFFER_TYPE_PRIORITY[a.offerType] - OFFER_TYPE_PRIORITY[b.offerType] ||
        a.name.localeCompare(b.name);
  });
};

export const CreateOrUpdateOrderDetailsForm: React.FC<Props> = ({
  offers,
  isOffersLoading,
  discounts,
  isDiscountsLoading,
  form
}) => {
  const [offerDiscountsMap, setOfferDiscountsMap] = useState<Record<string, Discount[]>>({});

  useEffect(() => {
    form.value.orderLines.forEach((orderLine, index) => {
      const offer = offers.find((offer) => offer.id === orderLine.offerId);
      if (offer && !isNil(offer.price)) {
        const discount = discounts.find((discount) => discount.id === orderLine.discountId);
        const price = discount ? calculatePrice(offer, discount) : offer.price;
        form.setValueByLabel(`orderLines[${index}].amount`, price);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newOfferDiscountsMap = form.value.orderLines.reduce((acc, orderLine) => {
      const offerId = Number(orderLine.offerId);
      const relatedDiscounts = discounts?.filter((discount) =>
        discount.offerIds.includes(offerId)
      ) || [];

      return {
        ...acc,
        [offerId]: relatedDiscounts
      };
    }, {});

    setOfferDiscountsMap(newOfferDiscountsMap);
  }, [form.value.orderLines, discounts]);
  const onOfferChange = (index: number, offer: Offer, reason: AutocompleteChangeReason) => {
    if (reason === "selectOption") {
      form.setValueByLabel(`orderLines[${index}].offerId`, offer.id);
      form.setValueByLabel(`orderLines[${index}].amount`, offer.price);
      if (offer.offerType === "CONTRACT") {
        form.setValueByLabel(`orderLines[${index}].quantity`, 1);
      }
      form.setValueByLabel(`orderLines[${index}].discountId`, null);
      form.setValueByLabel(`orderLines[${index}].amount`, offer.price);
    }
  };

  const onDiscountChange = (index: number, discount: Discount| null, reason: AutocompleteChangeReason) => {
    if (reason === "selectOption" && discount) {
      const offerId = form.value.orderLines[index].offerId;
      const offer = offers.find((offer) => offer.id === offerId);
      form.setValueByLabel(`orderLines[${index}].discountId`, discount.id);
      if (offer) {
        const price = calculatePrice(offer, discount);
        form.setValueByLabel(`orderLines[${index}].amount`, price);
      } else {
        form.setValueByLabel(`orderLines[${index}].amount`, null);
      }
    }
    if (reason === "clear") {
      const offerId = form.value.orderLines[index].offerId;
      const offer = offers.find((offer) => offer.id === offerId);
      form.setValueByLabel(`orderLines[${index}].discountId`, null);
      form.setValueByLabel(`orderLines[${index}].amount`, offer?.price || null);
    }
  };

  const calculatePrice = (offer: Offer, discount: Discount) => {
    let price = offer ? offer.price : 0;

    if (discount) {
      if (discount.discountType === "FIXED") {
        price -= discount.value;
      } else if (discount.discountType === "PERCENTAGE") {
        price -= Math.round(price * (discount.value / 100));
      }
    }

    return price;
  };

  return (
    <Grid container spacing={2}>
      {
        form.value.orderLines.map((orderLine: CreateOrderLine, index: number) => {
          return (
            <Grid item xs={12} key={index}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Autocomplete
                    loading={isOffersLoading}
                    options={buildOfferOptions(offers)}
                    value={offers.find((offer) => offer.id === form.value.orderLines[index].offerId) }
                    onChange={(event: SyntheticEvent, offer: Offer, reason: AutocompleteChangeReason) => {
                      return onOfferChange(index, offer, reason);
                    }}
                    getOptionLabel={(offer: Offer) => offer.name}
                    renderOption={(props: any, offer: Offer) => (
                      <Box component="li" {...props}>
                        {offerNameRenderer(offer)}
                      </Box>
                    )}
                    isOptionEqualToValue={(option: Offer, value: Offer) => value && option.id === value.id}
                    groupBy={(offer) => enumRenderer(offer.offerType, OfferType) || ""}
                    disableClearable
                    renderInput={(params) => (
                      <FormTextField
                        name={`orderLines[${index}].offerId`}
                        label="Offer *"
                        inputProps={params?.inputProps}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isOffersLoading ?
                                <CircularProgress color="inherit" size={20}/> : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                        form={form}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    loading={isDiscountsLoading}
                    options={offerDiscountsMap[form.value.orderLines[index].offerId] || []}
                    value={discounts.find((discount) => discount.id === form.value.orderLines[index].discountId)}
                    onChange={(event: SyntheticEvent, discount: Discount | null, reason: AutocompleteChangeReason) => {
                      return onDiscountChange(index, discount, reason);
                    }}
                    getOptionLabel={(discount: Discount) => {
                      if (form.value.orderLines[index].discountId === null) {
                        return "";
                      }
                      return discount.name;
                    }}
                    renderOption={(props: any, discount: Discount) => (
                      <Box component="li" {...props}>
                        {discount.name}
                      </Box>
                    )}
                    isOptionEqualToValue={(option: Discount, value: Discount) => {
                      return value && option.id === value.id;
                    }}
                    renderInput={(params) => (
                      <FormTextField
                        name={`orderLines[${index}].discountId`}
                        label="Discount"
                        inputProps={params?.inputProps}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isDiscountsLoading ?
                                <CircularProgress color="inherit" size={20}/> : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                        form={form}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormTextField
                    name={`orderLines[${index}].quantity`}
                    label="Quantity"
                    type="number"
                    required
                    form={form}
                    disabled={findOffer(offers, form.value.orderLines[index].offerId)?.offerType === "CONTRACT"}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormTextField
                    name={`orderLines[${index}].amount`}
                    label="Price per unit"
                    type="number"
                    required
                    form={form}
                    disabled={!isNil(findOffer(offers, form.value.orderLines[index].offerId)?.price)}
                  />
                </Grid>
                <Grid item xs={2}
                  sx={{ display: "flex", justifyContent: "center", alignItems: "start" }}>
                  <Button
                    sx={{ mt: 1 }}
                    onClick={() => form.removeArrayElement("orderLines", index)}
                    startIcon={<DeleteIcon/>}>
                      Remove
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          );
        })
      }
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={8}></Grid>
          <Grid item xs={2}>
            <TextField
              label="Total price"
              variant="outlined"
              disabled
              fullWidth
              value={calcTotalPrice(form.value.orderLines)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
        <Button variant="outlined"
          onClick={() => {
            form.setValueByLabel(`orderLines[${form.value.orderLines.length}]`, { offerId: "" });
          }}
          endIcon={<AddIcon />}>
            Add line
        </Button>
      </Grid>
    </Grid>
  );
};