import React from "react";

import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import { Box, Tooltip } from "@mui/material";

import { collectionPartnerIcons } from "../../components/collections/types";
import { Offer } from "../offers/types";
import { DeliveryOrderResourceType, Order } from "./types";

const buildOfferName = (offer: Offer) => {
  switch (offer.offerType) {
  case "PELLETS": {
    return `${offer.bagSize} kg`;
  }
  default: {
    return offer.name;
  }
  }
};

export const orderLinesRenderer = (record: Order) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
      {
        record.orderLines.map((line, index) => {
          return (
            <Box key={index}>
              {buildOfferName(line.offer)} x {line.quantity}
            </Box>
          );
        })
      }
    </Box>
  );
};

export const orderCompletionDetailsRenderer = (order: Order) => {
  if (order.deliveryOrder) {
    const deliveryOrder = order.deliveryOrder;
    return (
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-around", gap: 1 }}>
          #{deliveryOrder.id}
        {deliveryOrder.resourceType === DeliveryOrderResourceType.CUSTOMER &&
              <Tooltip title="Individual delivery"><PersonIcon/></Tooltip>}
        {deliveryOrder.resourceType === DeliveryOrderResourceType.GROUP_LEADER &&
              <Tooltip title="Group delivery"><GroupsIcon/></Tooltip>}
      </Box>
    );
  } else if (order.collectionPartner) {
    const collectionPartner = order.collectionPartner;
    return (
      <Tooltip title={`Picked up from ${collectionPartner.name}`}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-around", gap: 1 }}>
          {collectionPartner.code}
          {collectionPartnerIcons[collectionPartner.type]}
        </Box>
      </Tooltip>
    );
  } else {
    return null;
  }
};