import React, { useEffect, useState } from "react";

import { head } from "lodash-es";
import moment from "moment/moment";
import {
  DatagridConfigurable,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  TextField,
  useDataProvider,
  useListContext,
  useNotify
} from "react-admin";
import { Link } from "react-router-dom";

import { CarbonCreditClaimChip } from "../../components/carbon-credit-claims/CarbonCreditClaimChip";
import { useCustomerFilters } from "../../components/filter/customer-filters";
import {
  useDeviceIdFilter,
  useStoveDidsFilter,
  useStoveIxoAddressesFilter, useStoveNftIdsFilter,
  useStoveUidFilter
} from "../../components/filter/stove-filters";
import { PresetListActions } from "../../components/list/actions/PresetListActions";
import { DefaultPagination } from "../../components/pagination/DefaultPagination";
import {
  CustomerRecommendedLocationChip
} from "../../components/recommended-locations/RecommendedLocations";
import {
  CustomerRecommendedLocations
} from "../../components/recommended-locations/types";
import { useStoveCollectionFilter } from "../../components/stove/stovecollection/use-stove-collection-filter";
import { User } from "../../components/user/types";
import { UserRoles } from "../../core/providers/auth/roles";
import { PRIMARY_COLOR } from "../../core/theme/theme";
import { Resources } from "../../resources";
import { Customer, Stove } from "../../utils/commons";
import {
  cancelReasonChipColors,
  countryNameRenderer,
  customerRenderer,
  enumChipRenderer,
  enumRenderer, orderStatusChipColors,
  usernameRenderer
} from "../../utils/field-renderers";
import { useCheckAccess } from "../../utils/use-check-access";
import { OfferType } from "../offers/types";
import { CreateOrUpdateOrderButton } from "./actions/create/CreateOrUpdateOrderButton";
import { OrdersBulkActions } from "./actions/OrdersBulkActions";
import { CarbonCreditClaimButton } from "./CarbonCreditClaimButton";
import { orderCompletionDetailsRenderer, orderLinesRenderer } from "./renderers";
import { Order, OrderCancelReason, OrderLine, OrderStatus } from "./types";
import { useOrderFilters } from "./use-order-filters";

const showLocationForOrder = (order: Order) => {
  const dateBound = new Date("2023-11-20T00:00:00");
  return order.orderLines?.find((line: OrderLine) => line.offer?.offerType === "PELLETS")
      && moment(new Date(order.timeCreated)).isAfter(dateBound);
};

const renderRecommendedLocations = (order: Order, recommendedLocations: CustomerRecommendedLocations[]) => {
  const recommendedLocation =
      recommendedLocations.find((location) => location.customerId === order.customerId);
  const locationFound = recommendedLocation && recommendedLocation.recommendedLocations.length > 0;

  return showLocationForOrder(order) && locationFound
    ? <CustomerRecommendedLocationChip recommendedLocations={recommendedLocation.recommendedLocations}/>
    : null;
};

const renderCarbonCredits = (order: Order) => {
  return order.carbonCreditClaim ? <CarbonCreditClaimChip order={order}/> : null;
};
const OrdersDataGrid: React.FC = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { data, isLoading } = useListContext();
  const [recommendedLocations, setRecommendedLocations] = useState<CustomerRecommendedLocations[]>([]);
  const { hasAccess: showRecommendedLocations } =
      useCheckAccess([ UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_DELIVERY_MANAGER]);
  const { hasAccess: isAdmin } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);

  useEffect(() => {
    if (!isLoading && showRecommendedLocations) {
      const newOrdersCustomerIds =
          data
            .filter((order: Order) => showLocationForOrder(order))
            .map((order: Order) => order.customerId);
      const uniqueCustomerIds =
          newOrdersCustomerIds.filter((value, index, array) => array.indexOf(value) === index);

      dataProvider.getManyByUrlWithBody("orders/recommended-locations", { customerIds: uniqueCustomerIds })
        .then((response: {data: CustomerRecommendedLocations[]}) => {
          setRecommendedLocations(response.data);
          return response;
        }).catch(() => {
          notify("Error: failed to retrieve recommended locations", { type: "error" });
        });
    }
  }, [data, isLoading, notify, dataProvider, showRecommendedLocations]);

  return (
    <DatagridConfigurable bulkActionButtons={<OrdersBulkActions/>}
      omit={["transactionId", "carbonCreditStatus", "stoveId", "cancelReason", "timeCompleted"]}>
      <FunctionField
        source="id"
        render={(record: Order) =>
          <Link
            style={{ color: PRIMARY_COLOR, textDecoration: "none" }}
            to={`/orders/${record.id}/show`}>{record.id}
          </Link>}
      />
      <TextField
        source="transactionId"
        label="Transaction ID"
        sortable={false}/>
      <ReferenceField
        label="Customer"
        reference={Resources.Customers}
        source="customerId"
        link={false}
        sortable={false}>
        <FunctionField render={(customer: Customer) => customerRenderer(customer)}/>
      </ReferenceField>
      <ReferenceField
        label="Contract reference"
        reference={Resources.Customers}
        source="customerId"
        sortable={false}>
        <TextField source="contractReference"/>
      </ReferenceField>
      <ReferenceField
        label="Country"
        reference={Resources.Customers}
        source="customerId"
        sortable={false}>
        <FunctionField render={(record: Customer) => countryNameRenderer(record)}/>
      </ReferenceField>
      <FunctionField
        label="Order lines"
        render={(record: Order) => orderLinesRenderer(record)}/>
      <FunctionField
        label="Amount"
        source="amount"
        sortBy="amount"
        render={(record: Order) => `${record.amount} ${record.currency}`}/>
      <FunctionField
        label="Type"
        render={(record: Order) => enumRenderer(head(record.orderLines)?.offer.offerType, OfferType)}/>
      <FunctionField
        label="Status"
        sortBy="orderStatus"
        render={(record: Order) => enumChipRenderer(record.orderStatus, OrderStatus, orderStatusChipColors)}/>
      {isAdmin && <FunctionField
        label="Carbon Credit Claim Status"
        source="carbonCreditStatus"
        render={(record: Order) => renderCarbonCredits(record)}/>
      }
      {isAdmin &&
              <ReferenceField
                label="Device Id"
                reference={Resources.Stoves}
                source="stoveId"
                link={false}>
                <FunctionField source="deviceId" label="Device Id" render={(record: Stove) =>
                  <Link to={`/stoves/devices/${record.instanceId}/show`}
                    style={{ color: PRIMARY_COLOR, textDecoration: "none" }}
                  >{record.deviceId}</Link>
                }/>
              </ReferenceField>
      }
      <FunctionField
        label="Cancel Reason"
        source="cancelReason"
        render={(record: Order) =>
          record.cancelReason
            ? enumChipRenderer(record.cancelReason, OrderCancelReason, cancelReasonChipColors)
            : null}/>
      { showRecommendedLocations ? (
        <FunctionField
          label="Recommended Location"
          render={(order: Order) => renderRecommendedLocations(order, recommendedLocations)}
        />
      ) : null}
      <FunctionField
        label="Completion details"
        render={(record: Order) => orderCompletionDetailsRenderer(record)}/>
      <ReferenceField
        label="Created by"
        reference={Resources.Users}
        source="createdBy"
        sortable={false}
        link={false}>
        <FunctionField render={(user: User) => usernameRenderer(user)}/>
      </ReferenceField>
      <DateField
        source="timeCreated"
        label="Creation date"
        showTime/>
      <DateField
        source="timeCompleted"
        label="Completion date"
        sortable={false}
        showTime/>
    </DatagridConfigurable>
  );
};

export const OrdersList: React.FC = () => {
  const { hasAccess: isAdmin } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);
  const stoveFilters = [ useStoveUidFilter(false),
    useDeviceIdFilter(false),
    useStoveCollectionFilter(),
    useStoveDidsFilter(),
    useStoveIxoAddressesFilter(),
    useStoveNftIdsFilter()
  ];

  const filters = [
    ...useCustomerFilters({
      groupsAlwaysOn: true
    }),
    ...(isAdmin ? stoveFilters : []),
    ...useOrderFilters()
  ];

  return (
    <List
      filters={filters}
      actions={
        <PresetListActions selectColumnsEnabled>
          <>
            <CarbonCreditClaimButton/>
            <CreateOrUpdateOrderButton/>
          </>
        </PresetListActions>
      }
      pagination={<DefaultPagination/>}
      perPage={25}
      empty={false}
      exporter={false}
      sort={{ field: "timeCreated", order: "DESC" }}>
      <OrdersDataGrid/>
    </List>
  );
};