import React, { useState } from "react";

import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Tooltip,
  Typography
} from "@mui/material";
import {
  useDataProvider,
  useNotify,
  useRefresh
} from "react-admin";

import { UserRoles } from "../../../core/providers/auth/roles";
import { Customer } from "../../../utils/commons";
import { useCheckAccess } from "../../../utils/use-check-access";

type DefaultCustomerProps = {
  customer: Customer
};

export const DefaultCustomerButton: React.FC<DefaultCustomerProps> = ({
  customer
}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { hasAccess } = useCheckAccess([
    UserRoles.ROLE_SUPAMOTO_ADMIN
  ]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const handleCancel = () => {
    setIsFormOpen(false);
  };

  const handleConfirm = () => {
    setIsFormOpen(false);
    dataProvider.updateManyByUrlWithBody(`customers/${customer.id}/default`, {})
      .then((response: any) => {
        refresh();
        notify("Customer has been successfully defaulted");
        handleCancel();
        return response;
      }).catch(() => {
        notify("Error: failed to default customer", { type: "error" });
        handleCancel();
      });
  };
  return (
    <>
      <Tooltip title="Default">
        <span>
          <Button
            color="primary"
            variant="text"
            disabled={!hasAccess || customer.customerStatus === "INACTIVE"}
            onClick={() => setIsFormOpen(true)}
            startIcon={<RemoveCircleIcon/>}>
            {"Default"}
          </Button>
        </span>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isFormOpen}
        onClose={handleCancel}
      >
        <DialogTitle>Default</DialogTitle>
        <DialogContent>
          <DialogContent sx={{ mx: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" align="justify">
                    You are trying to default the customer with contract number
                  {" "}{customer.contractReference}.
                  The contract will be defaulted and device on paygops will be repossessed.
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCancel}>
                Cancel
            </Button>
            <Button
              autoFocus
              onClick={handleConfirm}>
                Confirm
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};