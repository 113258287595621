import React, { useState } from "react";

import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import {
  Box,
  Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Tooltip
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNotify } from "ra-core";
import {
  useDataProvider,
  useRefresh,
  useUnselectAll
} from "react-admin";
import * as yup from "yup";

import ConfirmationDialog from "../../../components/dialog/ConfirmationDialog";
import { FormTextField } from "../../../components/form/input/text-field/FormTextField";
import { useForm } from "../../../components/form/use-form";
import { StockEventSummaryDialog } from "../../../components/stock-events/StockEventSummaryDialog";
import {
  StockEvent
} from "../../../components/stock-events/types";
import { Resources } from "../../../resources";
import { usePageSafeSelector } from "../../../utils/use-page-safe-selector";

type MarkAsCompletedStockEventButtonProps = {
  disabled: boolean
};

type MarkAsCompletedFormState = {
  note: string | ""
};

const initialFormState: MarkAsCompletedFormState = {
  note: ""
};

export const MarkAsCompletedStockEventButton: React.FC<MarkAsCompletedStockEventButtonProps> = ({
  disabled
}) => {
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const unselect = useUnselectAll(Resources.StockEvents);
  const notify = useNotify();
  const form = useForm<MarkAsCompletedFormState>(initialFormState, yup.object({}));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [isCompletionRequestInProgress, setIsCompletionRequestInProgress] = useState(false);
  const { selectedIds, data } = usePageSafeSelector<StockEvent>();

  const handleCancel = () => {
    setIsDialogOpen(false);
    setIsConfirmationDialogOpen(false);
    form.reset();
  };

  const handleConfirm = () => {
    setIsCompletionRequestInProgress(true);
    dataProvider.updateManyByUrlWithBody("stock-events/complete", {
      eventIds: selectedIds,
      note: form.value.note
    })
      .then((response: any) => {
        refresh();
        notify("Stock Events have been successfully completed");
        unselect();
        handleCancel();
        setIsCompletionRequestInProgress(false);
        return response;
      }).catch(() => {
        notify("Error: failed to complete Stock Events", { type: "error" });
        setIsCompletionRequestInProgress(false);
      });
  };

  return (
    <>
      <Tooltip title="Mark Stock Events As Completed">
        <Button
          color="primary"
          disabled={disabled}
          onClick={() => setIsDialogOpen(true)}
          startIcon={<DomainVerificationIcon/>}>
            Mark As Completed
        </Button>
      </Tooltip>
      <Dialog fullWidth open={isDialogOpen} onClose={handleCancel}>
        <DialogTitle>Complete Stock Event(s)</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt:1 }}>
            <Grid item xs={12}>
              <FormTextField
                name="note"
                label="Note"
                form={form}/>
            </Grid>
            <Grid item xs={12}>
              <DialogContentText>
                Are you sure you want to complete Stock Event(s)?
              </DialogContentText>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>
            {"Don't cancel"}
          </Button>
          <Button
            disabled={isCompletionRequestInProgress}
            onClick={() => setIsConfirmationDialogOpen(true)}>
            {isCompletionRequestInProgress ?
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <CircularProgress color="secondary" size={20}/> Processing
              </Box>
              : "Confirm"
            }
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        title="Stock Event(s) to be completed:"
        isConfirmationDialogOpen={isConfirmationDialogOpen}
        isConfirmDisabled={isCompletionRequestInProgress}
        size="md"
        onClose={() => setIsConfirmationDialogOpen(false)}
        onConfirm={handleConfirm}>
        <StockEventSummaryDialog stockEvents={data}/>
      </ConfirmationDialog>
    </>
  );
};
