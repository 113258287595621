import * as React from "react";

import { Create, maxLength, minLength, required, SelectInput, SimpleForm, TextInput } from "react-admin";

import { COMPANY_CHOICES } from "../../components/company/types";
import { useNumberList } from "./use-number-list";

export const CompanyCreate = () => {
  const { textToArray, arrayToText } = useNumberList();

  return (
    <Create redirect="list">
      <SimpleForm>
        <TextInput source="name" validate={[required(), minLength(2), maxLength(50)]}/>
        <SelectInput source="type" validate={[required()]} choices={COMPANY_CHOICES}/>
        <TextInput source="nativeStoveIds"
          label="Stove UIDs"
          multiline
          parse={textToArray}
          format={arrayToText}
          sx={{ minWidth: "600px" }}
        />
      </SimpleForm>
    </Create>
  );
};
