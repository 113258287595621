import React, { useEffect, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions, DialogContent,
  DialogContentText, DialogTitle,
  Tooltip
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNotify } from "ra-core";
import {
  GetListParams,
  useDataProvider,
  useRefresh,
  useUnselectAll
} from "react-admin";
import * as yup from "yup";

import ConfirmationDialog from "../../../components/dialog/ConfirmationDialog";
import { FormSelect } from "../../../components/form/input/select/FormSelect";
import { FormTextField } from "../../../components/form/input/text-field/FormTextField";
import { useForm } from "../../../components/form/use-form";
import { StockEventSummaryDialog } from "../../../components/stock-events/StockEventSummaryDialog";
import {
  StockEvent,
  StockEventAdjustmentReason
} from "../../../components/stock-events/types";
import { Resources } from "../../../resources";
import { usePageSafeSelector } from "../../../utils/use-page-safe-selector";

type CancelStockEventButtonProps = {
  disabled: boolean
};

type StockEventCancellationFormState = {
  adjustmentReason: StockEventAdjustmentReason | "",
  note: string | ""
};

const initialFormState: StockEventCancellationFormState = {
  adjustmentReason: "",
  note: ""
};

export const CancelStockEventButton: React.FC<CancelStockEventButtonProps> = ({
  disabled
}) => {
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const unselect = useUnselectAll(Resources.StockEvents);
  const notify = useNotify();
  const form = useForm<StockEventCancellationFormState>(initialFormState, yup.object({}));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [isCancellationRequestInProgress, setIsCancellationRequestInProgress] = useState(false);
  const { selectedIds, data } = usePageSafeSelector<StockEvent>();
  const [eventsWithRelated, setEventsWithRelated] = useState<StockEvent[]>([]);

  const handleCancel = () => {
    setIsDialogOpen(false);
    setIsConfirmationDialogOpen(false);
    form.reset();
  };

  const stockEventsCancelReasons =
  ["WRONG_EVENT", "DOUBLE_EVENT", "OTHER_CANCELLATION"]
    .map((reason) =>
      ({ value: reason, label: StockEventAdjustmentReason[reason as keyof typeof StockEventAdjustmentReason] }));

  const handleConfirm = () => {
    setIsCancellationRequestInProgress(true);
    dataProvider.createManyByUrlWithBody("stock-events/cancel", {
      eventIds: selectedIds,
      note: form.value.note,
      adjustmentReason: form.value.adjustmentReason
    })
      .then((response: any) => {
        refresh();
        notify("Stock Events have been successfully updated");
        unselect();
        handleCancel();
        setIsCancellationRequestInProgress(false);
        return response;
      }).catch(() => {
        notify("Error: failed to cancel Stock Events", { type: "error" });
        setIsCancellationRequestInProgress(false);
      });
  };

  useEffect(() => {
    const originalEventIds = data
      .map((event) => event.id);
    if (originalEventIds.length > 0) {
      const params = { pagination: { page: 1, perPage: 500 },
        filter: { originalEventIds: originalEventIds } } as GetListParams;
      dataProvider.getList(Resources.StockEvents, params)
        .then((response) => {
          const combinedData = [...data, ...response.data];
          setEventsWithRelated(combinedData);
          return response;
        });
    } else {
      setEventsWithRelated(data);
    }
  }, [data, dataProvider]);

  return (
    <>
      <Tooltip title="Cancel Stock Events">
        <Button
          color="primary"
          disabled={disabled}
          onClick={() => setIsDialogOpen(true)}
          startIcon={<CancelIcon/>}>
            Cancel
        </Button>
      </Tooltip>
      <Dialog
        fullWidth
        open={isDialogOpen}
        onClose={handleCancel}
      >
        <DialogTitle>Cancel Stock Events</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt:1 }}>
            <Grid item xs={12}>
              <FormSelect
                name="adjustmentReason"
                label="Cancel Reason *"
                form={form}
                menuItems={stockEventsCancelReasons}/>
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="note"
                label="Note"
                form={form}/>
            </Grid>
            <Grid item xs={12}>
              <DialogContentText>
                Are you sure you want to cancel Stock Event(s)?
              </DialogContentText>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>
            {"Don't cancel"}
          </Button>
          <Button
            disabled={isCancellationRequestInProgress || !form.value.adjustmentReason}
            onClick={() => setIsConfirmationDialogOpen(true)}>
            {isCancellationRequestInProgress ?
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <CircularProgress color="secondary" size={20}/> Processing
              </Box>
              : "Confirm"
            }
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        title="Stock Events to be cancelled:"
        isConfirmationDialogOpen={isConfirmationDialogOpen}
        isConfirmDisabled={false}
        size="md"
        onClose={() => setIsConfirmationDialogOpen(false)}
        onConfirm={handleConfirm}>
        <StockEventSummaryDialog stockEvents={eventsWithRelated}/>
      </ConfirmationDialog>
    </>
  );
};