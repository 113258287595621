import * as React from "react";

import { AppBar as RaAppBar } from "react-admin";

export const AppBar: React.FC = (props: any) => (
  <RaAppBar
    {...props}
    color="primary"
  >
  </RaAppBar>
);
