import React from "react";

import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Grid from "@mui/material/Grid";

import { FormTextField } from "../../../components/form/input/text-field/FormTextField";
import { FormProps } from "../../../components/form/use-form";
import { Customer } from "../../../utils/commons";
import { Payment } from "../types";
import InsufficientFundsAlert, {
  calculateInsufficientFundDetails
} from "./InsufficientFundsAlert";
import { PaymentRefundFormState } from "./RefundPaymentButton";

type RefundDialogProps = {
  onClose: () => void,
  onConfirm: () => void,
  isConfirmDisabled: boolean,
  isProcessing: boolean,
  isOpened: boolean,
  payments: Payment[],
  form: FormProps<PaymentRefundFormState>,
  customers: Record<number, Customer>
};

export const RefundDialog: React.FC<RefundDialogProps> = ({
  onClose,
  onConfirm,
  isConfirmDisabled,
  isProcessing,
  isOpened,
  payments,
  form,
  customers
}) => {
  if (!payments.length) return <></>;

  const insufficientFundDetails = calculateInsufficientFundDetails(payments, customers);
  return <Dialog fullWidth maxWidth="sm" open={isOpened} onClose={onClose}>
    <DialogTitle>Refund payments</DialogTitle>
    <DialogContent>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <FormTextField required name="note" label="Note" form={form}/>
        </Grid>
        {insufficientFundDetails.customerPayments.length > 0 && (
          <InsufficientFundsAlert customerPayments={insufficientFundDetails.customerPayments}/>
        )}
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button
        disabled={isConfirmDisabled || insufficientFundDetails.customerPayments.length > 0 }
        onClick={onConfirm}>
        {isProcessing ?
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <CircularProgress color="secondary" size={20}/> Processing
          </Box>
          : "Confirm"
        }
      </Button>
    </DialogActions>
  </Dialog>;
};