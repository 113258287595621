import React, { ReactElement } from "react";

import CableIcon from "@mui/icons-material/Cable";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import TuneIcon from "@mui/icons-material/Tune";
import { Box, Tooltip, Typography } from "@mui/material";

import { CustomerGroupType } from "../../utils/commons";
import { enumRenderer } from "../../utils/field-renderers";
import { Offer, OfferType } from "./types";

export const offerIcons: Record<keyof typeof OfferType, ReactElement> = {
  "PELLETS": (
    <Tooltip title="Pellets offer">
      <LocalFireDepartmentIcon fontSize="small" color="warning"/>
    </Tooltip>
  ),
  "CONTRACT": (
    <Tooltip title="Contract offer">
      <ContactPageIcon fontSize="small" color="info"/>
    </Tooltip>
  ),
  "ACCESSORY": (
    <Tooltip title="Accessory offer">
      <CableIcon fontSize="small" color="success"/>
    </Tooltip>
  )
};

export const offerNameRenderer = (offer: Offer, justifyContent = "space-between") => {
  return (
    <Box sx={{
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent,
      gap: 1
    }}>
      <Typography>{offer.name}</Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {!offer.price &&
              <Tooltip title="Custom price per unit">
                <TuneIcon fontSize="small" color="secondary"/>
              </Tooltip>}
        {offerIcons[offer.offerType]}
      </Box>
    </Box>
  );
};

export const availableForRenderer = (availableFor: CustomerGroupType[]) => {
  return (
    <Box sx={{
      display: "flex",
      alignItems: "start",
      flexDirection: "column",
      justifyContent: "center"
    }}>
      {
        availableFor?.map((customerGroup) => {
          return (
            <Box key={customerGroup}>
              {enumRenderer(customerGroup, CustomerGroupType)}
            </Box>
          );
        })
      }
    </Box>
  );
};