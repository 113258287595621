import * as React from "react";

import { ToolbarProps } from "ra-ui-materialui";
import {
  Edit,
  maxLength,
  minLength,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar
} from "react-admin";

import { COMPANY_CHOICES } from "../../components/company/types";
import { useNumberList } from "./use-number-list";

const CompanyEditToolbar: React.FC = (props: ToolbarProps) => (
  <Toolbar {...props} >
    <SaveButton/>
  </Toolbar>
);

export const CompanyEdit: React.FC = () => {
  const { textToArray, arrayToText } = useNumberList();

  return (
    <Edit queryOptions={{ cacheTime: 0, staleTime: 0 }} mutationMode="pessimistic">
      <SimpleForm toolbar={<CompanyEditToolbar/>}>
        <TextInput source="id" disabled/>
        <TextInput source="name" validate={[required(), minLength(2), maxLength(50)]}/>
        <SelectInput source="type" validate={[required()]} choices={COMPANY_CHOICES}/>
        <TextInput source="nativeStoveIds"
          label="Stove UIDs"
          multiline
          parse={textToArray}
          format={arrayToText}
          sx={{ minWidth: "600px" }}
        />
      </SimpleForm>
    </Edit>
  );
};
