import React from "react";

import { UserRoles } from "../../../core/providers/auth/roles";
import { useCheckAccess } from "../../../utils/use-check-access";
import { usePageSafeSelector } from "../../../utils/use-page-safe-selector";
import { PelletsDeliveryAction } from "../delivery/PelletsDeliveryAction";
import { Order, OrderStatus } from "../types";
import { CancelOrderStatusButton } from "./CancelOrderStatusButton";
import EditOrderButton from "./EditOrderButton";
import { MarkAsPickedUpButton } from "./MarkAsPickedUpButton";

const CANCELLABLE_ORDERS: Array<keyof typeof OrderStatus> = ["NEW", "SENT_FOR_DELIVERY", "PICKED_UP", "COMPLETED"];

const isAllOrdersNew = (orders: Order[]) => {
  return orders.every((order) => order.orderStatus === "NEW");
};

export const OrdersBulkActions = () => {
  const { hasAccess: isAdmin } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);
  const { hasAccess: hasOrderModificationAccess } = useCheckAccess([
    UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_DELIVERY_MANAGER,
    UserRoles.ROLE_SHOP_KEEPER
  ]);
  const { hasAccess: hasDeliveryAccess } = useCheckAccess([
    UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_DELIVERY_MANAGER
  ]);

  const { data } = usePageSafeSelector<Order>();
  const isAllowedToCancel = (isAllOrdersNew(data) && hasOrderModificationAccess) ||
    (isAdmin && data.every((order) => CANCELLABLE_ORDERS.includes(order.orderStatus)));

  return (
    <>
      <PelletsDeliveryAction
        disabled={!hasDeliveryAccess || !isAllOrdersNew(data)}
      />
      <MarkAsPickedUpButton
        disabled={!hasOrderModificationAccess || !isAllOrdersNew(data)}/>
      <EditOrderButton />
      <CancelOrderStatusButton disabled={!isAllowedToCancel}/>
    </>
  );
};