import React from "react";

import { Box, CircularProgress } from "@mui/material";
import { Datagrid, DateField, EditButton, FunctionField, List, TextField, useGetMany } from "react-admin";

import { PresetListActions } from "../../components/list/actions/PresetListActions";
import { DefaultPagination } from "../../components/pagination/DefaultPagination";
import { UserRoles } from "../../core/providers/auth/roles";
import { Resources } from "../../resources";
import {
  countryNameRenderer,
  EnumChipColors, enumChipRenderer, enumRenderer
} from "../../utils/field-renderers";
import { useCheckAccess } from "../../utils/use-check-access";
import { filters } from "../discounts/filters";
import { availableForRenderer } from "../offers/renderers";
import { Offer } from "../offers/types";
import { Discount, DiscountStatus, DiscountType } from "./types";

const statusChipColors: Record<keyof typeof DiscountStatus, EnumChipColors> = {
  ACTIVE: "success",
  INACTIVE: "warning"
};

const OfferNames: React.FC<{ record: Discount }> = ({ record }) => {
  const { data: offers, isLoading } = useGetMany(Resources.Offers, { ids: record.offerIds || [] });
  if (isLoading) {
    return <CircularProgress size={20} />;
  }
  return (
    <Box>
      {offers ? offers.map((offer: Offer) => <Box key={offer.id}>{offer.name}</Box>) : ""}
    </Box>
  );
};

export const DiscountsList: React.FC = () => {
  const { hasAccess } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);

  return (
    <List
      filters={filters}
      actions={<PresetListActions exportingEnabled={false} createEnabled={hasAccess}/>}
      pagination={<DefaultPagination/>}
      perPage={25}
      exporter={false}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" sortable={false}/>
        <TextField source="name"/>
        <TextField source="description" sortable={false}/>
        <FunctionField label="Type"
          sortable={false}
          render={(discount: Discount) => enumRenderer(discount.discountType, DiscountType)}/>
        <TextField source="value" sortable={false}/>
        <FunctionField label="Country" sortable={false}
          render={(discount: Discount) => countryNameRenderer(discount)}/>
        <FunctionField
          label="Available for Offers"
          sortable={false}
          render={(record: Discount) => <OfferNames record={record} />}
        />
        <FunctionField
          label="Available for Groups"
          render={(discount: Discount) =>
            discount.restrictionGroups.length === 0 ? "All" : availableForRenderer(discount.restrictionGroups)
          }
        />
        <FunctionField label="Status"
          sortable={false}
          render={(discount: Discount) => enumChipRenderer(discount.discountStatus, DiscountStatus, statusChipColors)}/>
        <DateField source="timeCreated" label="Creation date" showTime/>
        <EditButton/>
      </Datagrid>
    </List>
  );
};