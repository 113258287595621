import React from "react";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Chip } from "@mui/material";
import { ChipTypeMap } from "@mui/material/Chip/Chip";
import { capitalize, isNil, split } from "lodash-es";
import { Link } from "react-router-dom";

import { User } from "../components/user/types";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../core/theme/theme";
import { OrderCancelReason, OrderStatus } from "../pages/orders/types";
import { Country, CountryCurrency, CountryHavingObject, Customer, Location } from "./commons";

enum PaygOpsBaseUrl {
  ZM = "https://supamoto.paygops.com",
  MW = "https://supamoto-mw.paygops.com",
  MZ = "https://supamoto-mz.paygops.com"
}
const stopPropagation = (e: any) => e.stopPropagation();

export const customerRenderer = (customer?: Customer) => {
  if (!customer) return;

  const baseUrl = PaygOpsBaseUrl[customer.country as keyof typeof PaygOpsBaseUrl];
  const customerType = customer.type === "CLIENT" ? "clients" : "leads";
  const href = `${baseUrl}/${customerType}/${customer.externalId}`;

  return <Box sx={{ display: "flex", alignItems: "center", gap: 1, color: PRIMARY_COLOR }}>
    <Box>
      <Link to={`/customers/${customer.id}/show`}
        target="_blank"
        style={{ color: PRIMARY_COLOR, textDecoration: "none" }}
        onClick={stopPropagation}>
        {customer.firstName} {customer.lastName}
      </Link>
    </Box>
    <Box sx={{ lineHeight: 0 }}>
      <a href={href} rel="noreferrer" target={"_blank"} onClick={stopPropagation}>
        <OpenInNewIcon sx={{ fontSize: "18px", fill: SECONDARY_COLOR }}/>
      </a>
    </Box>
  </Box>;
};

const USERNAME_MATCHER = new RegExp(/(?<name>.*)@.*/);
export const usernameRenderer = (user: User) => {
  const name = USERNAME_MATCHER.exec(user.username)?.groups?.name;
  if (name) {
    return split(name, ".").map(capitalize).join(" ");
  }

  return user.username;
};

export const countryNameRenderer = (obj: CountryHavingObject) => {
  if (!obj?.country) return;

  return Country[obj.country as keyof typeof Country] || obj.country;
};

export const priceWithCurrencyRenderer = (country: string, price?: number) => {
  if (isNil(price)) return;

  return `${price} ${CountryCurrency[country]}`;
};

export const capitalizeRenderer = (value: string) => isNil(value) ? value : capitalize(value);

export const enumRenderer = (value: string | undefined, enumObj: Record<string, string>) => {
  if (!value) return;
  return enumObj[value] || value;
};

export const enumIconRenderer = (value: string, enumObj: Record<string, string>, icons: Record<string, any>) => {
  return <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
    {icons[value]}
    <Box>{enumRenderer(value, enumObj)}</Box>
  </Box>;
};

export type EnumChipColors = ChipTypeMap["props"]["color"];

export const enumChipRenderer = (
  value: string,
  enumObj: Record<string, string>,
  colors: Record<string, EnumChipColors>) => {
  return <Chip
    label={enumRenderer(value, enumObj)}
    color={colors[value]}
    variant="outlined"
    size="small"
  />;
};

export const orderStatusChipColors: Record<keyof typeof OrderStatus, EnumChipColors> = {
  NEW: "secondary",
  SENT_FOR_DELIVERY: "info",
  DELIVERED: "success",
  PICKED_UP: "success",
  COMPLETED: "success",
  CANCELED: "warning"
};

export const cancelReasonChipColors: Record<keyof typeof OrderCancelReason, EnumChipColors> = {
  WRONG_CUSTOMER: "info",
  WRONG_AMOUNT: "warning",
  WRONG_BAG_SIZE: "success",
  WRONG_ORDER: "primary",
  WRONG_PICK_UP_LOCATION: "secondary",
  WRONG_ACCOUNT: "error",
  CLIENT_ALREADY_PICKED_UP: "info",
  CALLS_NOT_ANSWERED_AFTER_MAKING_PAYMENT: "warning"
};

const roundLocation = (location: number) => location.toFixed(6);
export const geolocationRenderer = (resource: { latitude: number, longitude: number }) => {
  if (resource.latitude && resource.longitude) {
    return (
      <a href={`https://www.google.com/maps/search/?api=1&query=${resource.latitude},${resource.longitude}`}
        style={{ color: PRIMARY_COLOR, textDecoration: "none" }}
        rel="noreferrer"
        target={"_blank"}
        onClick={(e: any) => e.stopPropagation()}
      >{roundLocation(resource.latitude)}, {roundLocation(resource.longitude)}
      </a>
    );
  }

  return null;
};

export const locationHierarchyRenderer = (locationsHierarchy: Location[]) => {
  const sortedLocations = [...locationsHierarchy].sort((a, b) => b.level - a.level);
  return sortedLocations.map((location) => location.name).join(" > ") || "-";
};

export const generateChoicesFromEnum = (enumObject: object) => {
  return Object.entries(enumObject).map(([key, value]) => ({ id: key, name: value }));
};

export const generateFormSelectChoicesFromEnum = (enumObject: object) => {
  return Object.entries(enumObject).map(([key, value]) => ({ value: key, label: value }));
};
