import React from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

type ErrorItem = {
  lineNumber?: number,
  message: string
};

export type ErrorData = {
  errorCode: string,
  message: string,
  errors?: ErrorItem[],
  unprocessedItems?: any[]
};

export type UploadFeedbackProps = {
  data: ErrorData
};

export const UploadFeedback: React.FC<UploadFeedbackProps> = ({ data }) => {
  if (!data || !data.errors || data.errors.length === 0) {
    return <></>;
  }

  return <TableContainer component={Paper} sx={{ mt: 2 }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Line number</TableCell>
          <TableCell>Message</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.errors.map((error, index) => (
          <TableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell>{error.lineNumber}</TableCell>
            <TableCell>{error.message}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>;
};