import React, { useState } from "react";

import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Tooltip,
  Typography
} from "@mui/material";
import {
  useDataProvider,
  useNotify,
  useRefresh
} from "react-admin";
import { Link } from "react-router-dom";
import * as yup from "yup";

import { FormSelect } from "../../../components/form/input/select/FormSelect";
import { useForm } from "../../../components/form/use-form";
import { UserRoles } from "../../../core/providers/auth/roles";
import { Customer, RepossessionReason, Stove } from "../../../utils/commons";
import { useCheckAccess } from "../../../utils/use-check-access";

type RepossessDeviceProps = {
  customer: Customer | undefined,
  stove?: Stove
};

type RepossessDeviceFormState = {
  reason: RepossessionReason | ""
};

const initialFormState: RepossessDeviceFormState = {
  reason: ""
};
const validationSchema = yup.object({
  reason: yup.string().required()
});
const repossessionReasons = Object.entries(RepossessionReason).map(([key, value]) => {
  return { value: key, label: value };
})
  .sort((a, b) => a.label.localeCompare(b.label));

export const RepossessDeviceButton: React.FC<RepossessDeviceProps> = ({
  customer,
  stove
}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const form = useForm<RepossessDeviceFormState>(initialFormState, validationSchema);
  const { hasAccess } = useCheckAccess([
    UserRoles.ROLE_SUPAMOTO_ADMIN
  ]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const handleCancel = () => {
    setIsFormOpen(false);
    form.reset();
  };

  const handleConfirm = () => {
    setIsFormOpen(false);
    dataProvider.createManyByUrlWithBody("customers/repossess", {
      customerId: customer?.id,
      stoveId: stove?.id,
      reason: form.value.reason
    }).then((response: any) => {
      refresh();
      notify("Device has been successfully repossessed");
      handleCancel();
      return response;
    }).catch(() => {
      notify("Error: failed to repossess device", { type: "error" });
      handleCancel();
    });
  };
  return (
    <>
      <Tooltip title="Repossess">
        <span>
          <Button
            color="primary"
            variant="text"
            disabled={!hasAccess ||
                (customer && customer.customerStatus === "INACTIVE") ||
                !stove ||
                stove.status === "INACTIVE"}
            onClick={() => setIsFormOpen(true)}
            startIcon={<RemoveCircleIcon/>}>
            {"Repossess"}
          </Button>
        </span>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isFormOpen}
        onClose={handleCancel}
      >
        <DialogTitle>Repossess</DialogTitle>
        <DialogContent>
          <DialogContent sx={{ mx: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" align="justify">
                  {customer && stove ? (
                    <>
                        You are trying to repossess the device from the customer with contract number
                      {" "}{customer.contractReference}.
                        The device with Stove UID{" "}
                      <Link to={`/stoves/${stove.id}/show`}>
                        {stove.nativeId}
                      </Link>{" "}
                        will be repossessed and the contract will be defaulted.
                    </>
                  ) : (
                    "The stove will be repossessed (inactivated)."
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <FormSelect
                  name="reason"
                  label="Repossession Reason *"
                  form={form}
                  menuItems={repossessionReasons}/>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              autoFocus
              disabled={!form.isValid}
              onClick={handleConfirm}>
              Confirm
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};