import React from "react";

import { isNil } from "lodash-es";
import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List, ReferenceField,
  TextField
} from "react-admin";

import { PresetListActions } from "../../components/list/actions/PresetListActions";
import { DefaultPagination } from "../../components/pagination/DefaultPagination";
import { StockItem } from "../../components/stock-item/types";
import { UserRoles } from "../../core/providers/auth/roles";
import { Resources } from "../../resources";
import {
  countryNameRenderer,
  EnumChipColors,
  enumChipRenderer,
  enumIconRenderer,
  priceWithCurrencyRenderer
} from "../../utils/field-renderers";
import { useCheckAccess } from "../../utils/use-check-access";
import { availableForRenderer, offerIcons } from "./renderers";
import { Offer, OfferStatus, OfferType } from "./types";
import { useOfferFilters } from "./use-offer-filters";

const statusChipColors: Record<keyof typeof OfferStatus, EnumChipColors> = {
  ACTIVE: "success",
  INACTIVE: "warning",
  ARCHIVED: "secondary"
};

export const OffersList: React.FC = () => {
  const { hasAccess } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);

  return (
    <List
      filters={useOfferFilters()}
      actions={<PresetListActions exportingEnabled={false} createEnabled={hasAccess}/>}
      pagination={<DefaultPagination/>}
      perPage={25}
      exporter={false}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" sortable={false}/>
        <TextField source="name"/>
        <FunctionField label="Price"
          sortable={true}
          sortBy={"price"}
          render={(offer: Offer) => priceWithCurrencyRenderer(offer.country, offer.price) ?? "Custom"}/>
        <ReferenceField label="Stock Item" reference={Resources.StockItems} source="stockItemId" link={false}>
          <FunctionField source="stockItemId" render={(record:StockItem) => record.name}/>
        </ReferenceField>
        <BooleanField label="Rebated" source="isRebated" />
        <FunctionField label="Available For" sortable={true}
          render={(offer: Offer) => availableForRenderer(offer.availableFor)}/>
        <FunctionField label="Type"
          sortable={true}
          sortBy={"offerType"}
          render={(offer: Offer) => enumIconRenderer(offer.offerType, OfferType, offerIcons)}/>
        <FunctionField label="Country" sortable={true}
          sortBy={"country"}
          render={(offer: Offer) => countryNameRenderer(offer)}/>
        <FunctionField label="Status"
          sortable={true}
          sortBy={"offerStatus"}
          render={(offer: Offer) => enumChipRenderer(offer.offerStatus, OfferStatus, statusChipColors)}/>
        <DateField source="timeCreated" label="Creation date" showTime/>
        {hasAccess && <FunctionField label="Edit"
          render={(offer: Offer) => (<EditButton disabled={offer.offerStatus === "ARCHIVED" || isNil(offer.price)}/>)}
        />}
      </Datagrid>
    </List>
  );
};